import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-3af8df2bbd/0/cache/next-npm-14.2.24-46d9115212-103d1eed8f.zip/node_modules/next/dist/client/components/app-router.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-3af8df2bbd/0/cache/next-npm-14.2.24-46d9115212-103d1eed8f.zip/node_modules/next/dist/client/components/client-page.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-3af8df2bbd/0/cache/next-npm-14.2.24-46d9115212-103d1eed8f.zip/node_modules/next/dist/client/components/error-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-3af8df2bbd/0/cache/next-npm-14.2.24-46d9115212-103d1eed8f.zip/node_modules/next/dist/client/components/layout-router.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-3af8df2bbd/0/cache/next-npm-14.2.24-46d9115212-103d1eed8f.zip/node_modules/next/dist/client/components/not-found-boundary.js");
;
import(/* webpackMode: "eager" */ "/app/.yarn/__virtual__/next-virtual-3af8df2bbd/0/cache/next-npm-14.2.24-46d9115212-103d1eed8f.zip/node_modules/next/dist/client/components/render-from-template-context.js");
